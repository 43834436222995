* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
}

body {
	font-size: 110%;
	color: #ecf0f1;
	font-family: 'Source Sans Pro', sans-serif;
	background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
}